<template>
    <!-- <chevron-holder :page-title="pageTitle" :tabs="tabs"></chevron-holder> -->
    <div class="">
        <div class="row page-title">
            <label v-if="IsEdit">Edit Media Vendor Request</label>
            <label v-else>New Media Vendor Request</label>

        </div>
        <div class="row stepper-outer">
            <div class="col-lg-10 mx-auto chevron-steps-container">
                <chevron-steps :steps="tabs" @click="navigate"></chevron-steps>
            </div>
        </div>
        <div class="pt-30 row">
            <div class="col-lg-12">
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
const LedgerMediaInfo = () => import("../components/Vendor/Media/LedgerMediaInfo");
const VendorCreditCardInfo = () => import("../components/Vendor/Media/CreditCardInfo");
const VendorSetupInfo = () => import("../components/Vendor/Media/SetupInfo");
const EmployeeInfo = () => import("../components/Vendor/Media/EmployeeInfo");
const EmployeeRelationship = () => import("../components/Vendor/Media/EmployeeRelationship");
const ExternalForm = () => import("../components/Vendor/Media/Attestation");
const Review = () => import("../components/Vendor/Media/Review");
// const ChevronHolder = () => import("../components/Common/ChevronHolder");
export default {
    // components: {
    //     'chevron-holder': ChevronHolder
    // },
    mounted() {
        let id = this.id;
        if (id) {

            if (this.isDraft == true) {
                this.$store.dispatch("mediavendor/getDraftData", parseInt(id));
            } else {
                this.$store.dispatch("mediavendor/GetFormData", parseInt(id))
                    .catch((err) => {
                        if (err.response && err.response.status == 403) {
                            this.$router.push({
                                name: "error",
                                params: {
                                    message: "UnAuthorized"
                                },
                            });
                        }
                        else {
                            this.Error = true;
                            alert(err);
                        }
                    });
            }
        }
        this.$emit("can-continue", {
            value: true
        });
        this.$on("stepChanged", (tabIndex) => {
            this.UpdateSteps(tabIndex);
        });
        this.$on("stepInitiated", (tabIndex) => {
            this.InProgressStep(tabIndex);
        });
        // if (window.performance.navigation.type === 1) {
        //     this.$router.push({ name: 'Media Vendor Ledger Media' });
        // }
    },
    methods: {
        UpdateSteps: function (index) {
            for (var i = 0; i < this.tabs.length; i++) {
                if (i <= index || index == this.tabs.length - 1) {
                    this.$set(this.tabs[i], "state", "complete");
                    this.completedStep = i;
                }
            }
        },
        InProgressStep: function (index) {
            for (var i = 0; i < this.tabs.length; i++) {
                if (i == index) {
                    this.$set(this.tabs[i], "state", "in progress");
                } else if (i <= this.completedStep) {
                    this.$set(this.tabs[i], "state", "complete");
                } else if (this.tabs[i].state != "complete") {
                    this.$set(this.tabs[i], "state", "");
                }
            }
            if (this.completedStep < 0 && index > 0) {
                if (this.id) {
                    this.$router.push({
                        path: `${this.tabs[0].route}/${parseInt(this.id)}`,
                    });
                } else {
                    this.$router.push({
                        path: this.tabs[0].route,
                    });
                }
            }
        },
        enableNext: function () {
            this.$emit("can-continue", {
                value: true
            });
        },
        navigate(index) {
            window.console.log("navigate");
            let currentRouteIndex = this.$route.meta.tabIndex;
            if (index > this.completedStep + 1 && index > currentRouteIndex) {
                //this.$store.dispatch("progress/setErrorMessage","Please complete previous steps to proceed!");
                // this.$root.toastDanger("check color");
            } else {
                if (this.id) {
                    this.$router.push(`${this.tabs[index].route}/${this.id}`);
                } else {
                    this.$router.push(this.tabs[index].route);
                }
            }
        },
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false,
        },
    },
    created() {
        // localStorage.setItem('clienticId',null);
        this.$store.commit("mediavendor/reset");
        // if (this.id && this.isDraft != true) {
        //   this.completedStep = 4;
        // }
    },
    data() {
        return {
            pageTitle: 'New Media Vendor Request',
            completedStep: -1,
            tabs: [
                {
                    icon: "1",
                    name: "1",
                    description: "Ledger & Media Type",
                    route: "/Request/MediaVendor/LedgerMedia",
                    component: LedgerMediaInfo,
                    state: "in progress",
                },
                {
                    icon: "2",
                    name: "2",
                    description: "External Vendor Form",
                    route: "/Request/MediaVendor/ExternalForm",
                    component: ExternalForm,
                    state: this.id && this.isDraft != true ? "complete" : "",
                },
                {
                    icon: "3",
                    name: "3",
                    description: "Credit Card Info ",
                    component: VendorCreditCardInfo,
                    route: "/Request/MediaVendor/CreditCardInfo",
                    state: this.id && this.isDraft != true ? "complete" : "",
                },
                {
                    icon: "4",
                    name: "4",
                    description: "Setup Info",
                    route: "/Request/MediaVendor/SetupInfo",
                    component: VendorSetupInfo,
                    state: this.id && this.isDraft != true ? "complete" : "",
                },
                {
                    icon: "5",
                    name: "5",
                    description: "Employee Relationship",
                    route: "/Request/MediaVendor/EmployeeRelationship",
                    component: EmployeeRelationship,
                    state: this.id && this.isDraft != true ? "complete" : "",
                },
                {
                    icon: "6",
                    name: "6",
                    description: "Employee Info",
                    route: "/Request/MediaVendor/EmployeeInfo",
                    component: EmployeeInfo,
                    state: this.id && this.isDraft != true ? "complete" : "",
                },
                {
                    icon: "7",
                    name: "7",
                    description: "Review",
                    route: "/Request/MediaVendor/Review",
                    component: Review,
                    state: "",
                },
            ],
        };
    },
    watch: {},
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
    },
};
</script>

<style scoped>
.stepper-box,
.stepper-box .top .steps-wrapper .step .circle {
    background-color: transparent !important;
}

.content-holder {
    background-color: #ffffff !important;
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.progress-bar[data-v-ef603d5c]:not(.hide-number):before {
    width: 30px !important;
    height: 30px !important;
    padding-top: 6px !important;
}
</style>
